<template>
    <div class="charts-dynamics" v-if="loaded">
        <line-chart
            style="height: 250px"
            :chartdata="chartdata"
            :options="options"
        />
        <div class="charts-dynamics__empty" v-if="!chartHasData">
            <img src="@/assets/img/folder-empty.svg" alt="svg">
            <p>{{ $t('Empty data') }}</p>
        </div>
    </div>
    <Preloader v-else></Preloader>
</template>

<script>
import LineChart from "@/plugins/LineChart.js";
import Preloader from "@/components/ui/Preloader"

export default {
    components: {
        Preloader,
        LineChart
    },

    data() {
        return {
            chartdata: {},
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                    label: (tooltipItem) => {
                        return tooltipItem.yLabel;
                    }
                  }
                },
                legend: {
                    position: 'bottom'
                },
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                stacked: false,
                scales: {
                    yAxes: [{
                        ticks: {
                        min: 0,
                        beginAtZero: true,
                        callback: function(value, index, values) {
                            if (Math.floor(value) === value) {
                                return value;
                            }
                        }
                        }
                    }]
                }
            },
            chartHasData: false,
            loaded: true
        }
    },

    computed: {
        getAppliedDynamicsFilters() {
            return this.$store.getters.getAppliedDynamicsFilters
        },
        getDynamicsSortByType() {
            return this.$store.getters.getDynamicsSortByType
        },
        getNewAnalytic() {
            return this.$store.getters.getNewAnalytic
        },
    },

    watch: {
        getAppliedDynamicsFilters(allFilters) {
            if (allFilters.format) {
                this.getDynamics(allFilters)
            }
        },
        getNewAnalytic(v) {
            if (v) {
                this.getDynamics(this.getAppliedDynamicsFilters)
                this.$store.dispatch('setNewAnalytic', false)
            }
        }
    },

    mounted() {
        this.getDynamics(this.getAppliedDynamicsFilters)
    },

    methods: {

        setDynamicData(data)
        {
            let sortedUniqueCountFirstGraph = data.reduce(function (r, a) {
                r[a.date] = r[a.date] || [];
                r[a.date].push(a);
                return r;
            }, {})


            let newDataSet = [];
            for (const c in sortedUniqueCountFirstGraph) {
                let summ = 0;
                sortedUniqueCountFirstGraph[c].map((elem) => {
                    this.getDynamicsSortByType == 'money' ? summ = summ + (+elem.amount_sum) : summ = summ + (+elem.count_sum)

                })
                newDataSet.push({ date: sortedUniqueCountFirstGraph[c][0].date, summ})
            }

            let datasetsMain = [];
            let dataLabels = [];
            newDataSet.sort((a, b) => (a.date > b.date) ? 1 : -1).map(e => {
                datasetsMain.push(e.summ)
                if (e.date.includes('/')) {
                    let weekNumber = e.date.substr(e.date.indexOf('/') + 1)
                    let monday = this.moment().day("Monday").week(+weekNumber);
                    dataLabels.push(this.moment(monday).format("YYYY-MM-DD"));
                }
                else {
                    dataLabels.push(e.date)
                }
            })

            return [
                datasetsMain,
                dataLabels
            ]
        },
        async getDynamics(params) {
            try {
                this.loaded = false;
                let dynamics = await this.ApiService.ticket.getDynamics(params).catch(e => {
                    if (e?.messages?.date_from) {
                        this.toast.error(e.messages.date_from)
                    }
                })

                this.loaded = true;

                let datasetsMain, dataLabels;
                if (dynamics?.data.length) {
                    this.chartHasData = true

                    /* FIRST LINE */
                    if (!dynamics.data[0].stats?.data?.length) {return false}

                    [datasetsMain, dataLabels] = this.setDynamicData(dynamics.data[0].stats.data)
                    /* FIRST LINE END */

                    this.chartdata = {
                        labels: dataLabels,
                        datasets: [
                            {
                                data: datasetsMain,
                                label: dynamics.data[0].name,
                                borderCapStyle: "string",
                                pointRadius: 6,
                                backgroundColor: 'transparent',
                                borderColor: '#70dc95',
                                pointBackgroundColor: '#70dc95',
                                pointBorderWidth: 2,
                                pointBorderColor: "white",
                            },
                        ]
                    };

                    if (dynamics?.data.length == 2) {
                        if (!dynamics.data[0].stats?.data?.length) {return false}

                        [datasetsMain, dataLabels] = this.setDynamicData(dynamics.data[1].stats.data)
                        this.chartdata.labels = dataLabels
                        this.chartdata.datasets.push({
                            data: datasetsMain,
                            label: dynamics.data[1].name,
                            borderCapStyle: "string",
                            pointRadius: 6,
                            backgroundColor: 'transparent',
                            borderColor: '#ff496d',
                            pointBackgroundColor: '#ff496d',
                            pointBorderWidth: 2,
                            pointBorderColor: "white",
                        })

                    }

                  /*  if (dynamics?.data.length == 2) {
                        if (!dynamics.data[1].stats?.data?.length) { return false }

                        let sortedUniqueCountSecondGraph = dynamics.data[1].stats.data.reduce(function (r, a) {
                            r[a.date] = r[a.date] || [];
                            r[a.date].push(a);
                            return r;
                        }, {})

                        let newDataSetSecond = [];
                        for (const c in sortedUniqueCountSecondGraph) {
                            let summ = 0;
                            sortedUniqueCountSecondGraph[c].map((elem) => {
                                this.getDynamicsSortByType == 'money' ? summ = summ + (+elem.amount_sum) : summ = summ + (+elem.count_sum)
                            })
                            newDataSetSecond.push({ date: sortedUniqueCountSecondGraph[c][0].date, summ})
                        }

                        let datasetsMainSecond = [];
                        let dataLabelsSecond = [];
                        newDataSetSecond.sort((a, b) => (a.date > b.date) ? 1 : -1).map(e => {
                            if (e.date.includes('/')) {
                                let weekNumber = e.date.substr(e.date.indexOf('/') + 1)
                                let monday = this.moment().day("Monday").week(+weekNumber);
                                dataLabelsSecond.push(this.moment(monday).format("YYYY-MM-DD"));
                            }
                            else {
                                dataLabelsSecond.push(e.date)
                            }
                        })

                        let resultLabels = dataLabels.concat(dataLabelsSecond);
                        resultLabels = Array.from(new Set(resultLabels));

                        newDataSetSecond.sort((a, b) => (a.date > b.date) ? 1 : -1).map(e => {
                            resultLabels.map(elem => {
                                let formattedDate;
                                if (e.date.includes('/')) {
                                    let weekNumber = e.date.substr(e.date.indexOf('/') + 1)
                                    formattedDate = this.moment().day("Monday").week(+weekNumber);
                                }
                                else {
                                    formattedDate = e.date
                                }

                                let momentFormatted = formattedDate

                                if (typeof formattedDate == 'object') {
                                    momentFormatted = this.moment(formattedDate).format("YYYY-MM-DD")
                                }

                                momentFormatted == elem ? datasetsMainSecond.push(e.summ) : datasetsMainSecond.push(0)
                            })
                        })

                        this.chartdata.datasets.push({
                            data: datasetsMainSecond,
                            label: dynamics.data[1].name,
                            borderCapStyle: "string",
                            pointRadius: 6,
                            backgroundColor: 'transparent',
                            borderColor: '#ff496d',
                            pointBackgroundColor: '#ff496d',
                            pointBorderWidth: 2,
                            pointBorderColor: "white",
                        })

                        this.chartdata.labels = resultLabels
                    }*/

                    if (this.chartdata.labels.length == 1) {
                        this.chartdata.labels.push('')
                        this.chartdata.datasets[0].data.push(this.chartdata.datasets[0].data[0])

                        if (this.chartdata?.datasets[1]?.data[0]) {
                            this.chartdata.datasets[1].data.push(this.chartdata.datasets[1].data[0])
                        }
                    }

                }
                else {
                    this.chartHasData = false
                    this.chartdata = {
                        labels: [this.$t('January'), this.$t('February'), this.$t('March'), this.$t('April'), this.$t('May'), this.$t('June'), this.$t('July'), this.$t('August'), this.$t('September'), this.$t('October'), this.$t('November'), this.$t('December')],
                        datasets: [
                            {
                                data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                                label: '',
                                borderCapStyle: "string",
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                pointBackgroundColor: 'transparent',
                            }
                        ]
                    };
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .charts-dynamics {
        position: relative;

        &__empty {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: $md) {
                top: 98px
            }

            img {
                height: 100px;
            }

            p {
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: #141213;
                margin-top: 10px;

                @media screen and (max-width: $md) {
                    font-size: 14px;
                }
            }
        }
    }
</style>
