<template>
    <div class="dynamics">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <Breads :links="breadLinks"/>
                    <PageHeader :title="$t('Dynamics')"/>

                    <div class="page-content dynamics__content">
                        <div class="dynamics__header">
                            <DatePickerComponent
                                class="dynamics__picker"
                                name="date"
                                v-model="filtersData.date"
                                :clearable="true"
                                :range="true"
                                :valueType="'format'"
                                :showLabel='true'
                                :placeholder="$t('Date')"
                            />

                            <div class="btn-container">
                                <div class="block">
                                    <div class="sort-by">
                                        <button @click="sortByType('money')" :class="{active : sortBy.sort==='money'}"
                                                class="btn">{{ $t('Ticket price') }}
                                        </button>
                                        <button @click="sortByType('quantity')"
                                                :class="{active : sortBy.sort==='quantity'}" class="btn">
                                            {{ $t('Spectators') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="block">
                                    <div class="date-container">
                                        <button @click="sortBy.time='day'" :class="{active : sortBy.time==='day'}"
                                                class="btn">{{ $t('Day') }}
                                        </button>
                                        <button @click="sortBy.time='week'" :class="{active : sortBy.time==='week'}"
                                                class="btn">{{ $t('Week') }}
                                        </button>
                                        <button @click="sortBy.time='month'" :class="{active : sortBy.time==='month'}"
                                                class="btn">{{ $t('Month') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dynamics__comparison">
                            <div class="mb-3 w-100">
                                <SelectComponent
                                    :required="true"
                                    @search="movieFromChangeSearch" ref="movieFrom"
                                    @input="movieFromChange"
                                    :lightblue="true"
                                    :options="movieListFrom"
                                    :show-label="true"
                                    :placeholder="$t('Film')"
                                    :value="movie_from"
                                    name="movieFrom"
                                />
                            </div>
                            <span class="dynamics__comparisonIcon material-icons">compare_arrows</span>
                            <div class="mb-3 w-100">
                                <SelectComponent
                                    :required="true"
                                    @search="movieToChangeSearch"
                                    ref="movieTo"
                                    @input="movieToChange"
                                    :lightblue="true"
                                    :options="movieListTo"
                                    :show-label="true"
                                    :placeholder="$t('Compare With')" :value="movie_to"
                                    name="movieTo"/>
                            </div>
                        </div>
                        <div class="dynamics__cinemaCity">
                            <SelectComponent
                                ref="getcities"
                                @input="citiesChange"
                                :clearable="true"
                                :lightblue="true"
                                :options="cities"
                                :show-label="true"
                                :placeholder="$t('City')"
                                @search="searchCities"
                                :value="filtersData.region_id"
                                name="region_id"
                            />
                            <MultiselectComponent
                                :value="cinemasValue"
                                @change="multiselectChangeCinemas"
                                v-if="isCinemasLoaded"
                                :options="allCinemasList"
                                :show-label="true"
                                :placeholder="$t('Click on the box to select cinemas')"
                                :label="$t('Cinema')"
                                name="cinemas"
                                class="mt-4 mt-sm-0"
                                :multiselectClass="'multiselect--mini'"
                            />
                            <Preloader v-else/>
                        </div>
                        <ButtonComponent class="me-2 mt-3" size="flexible" :caption="$t('Apply')"
                                         @click="applyFilters()"/>
                        <div class="dynamics__chart mt-5 pb-5">
                            <ChartsDynamics/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Breads from "@/components/Breads";
import SelectComponent from "@/components/ui/forms/SelectComponent"
import DatePickerComponent from "@/components/ui/forms/DatePickerComponent"
import ButtonComponent from "@/components/ui/ButtonComponent"
import MultiselectComponent from "@/components/ui/forms/MultiselectComponent";
import ChartsDynamics from "@/components/ui/charts/ChartsDynamics"
import Preloader from "@/components/ui/Preloader"

export default {
    components: {
        PageHeader,
        Breads,
        SelectComponent,
        DatePickerComponent,
        MultiselectComponent,
        ButtonComponent,
        ChartsDynamics,
        Preloader
    },
    data() {
        return {
            filtersData: {
                date: [new Date().toISOString().slice(0, 10), new Date().toISOString().slice(0, 10)],
                region_id: '',
            },
            sortBy: {
                time: 'day',
                sort: 'money'
            },
            isMoviesLoaded: false,
            movie_from: '',
            movie_to: '',
            cities: [],
            movieListFrom: [],
            movieListTo: [],
            breadLinks: [
                {
                    label: this.$t('Dynamics'),
                    to: {name: 'Dynamics'}
                }
            ],
            cinemasValue: '',
            isCinemasLoaded: false,
            allCinemasList: [],
        }
    },

    methods: {
        sortByType(t) {
            this.sortBy.sort = t
            this.$store.commit('setDynamicsSortByType', t)
        },
        citiesChange(v) {
            this.filtersData.region_id = v
        },

        applyFilters() {
            if (!this.movie_from || !this.movie_to) {
                this.toast.error(this.$t('Choose movies to compare'))
                return false
            }
            let region_id = this.filtersData.region_id
            let format = this.sortBy.time
            let date_from = this.filtersData.date[0]
            let date_to = this.filtersData.date[1]
            let movies = [this.movie_from, this.movie_to]
            let cinemas = this.cinemasValue ? this.cinemasValue : []

            this.$store.dispatch('addDynamicsFilters', {cinemas, region_id, format, movies, date_from, date_to})
        },

        async searchCities(v) {
            if (!v) {
                v = undefined
            }
            await this.getCities({cities: 1, name: v})
        },

        async getCities(data = null) {
            let cities = await this.ApiService.city.getCities(data)
            cities.map(elem => {
                elem.label = elem.full_name
                return elem
            })
            this.cities = cities;
            if (this.$refs.getcities) {
                this.$refs.getcities.$forceUpdate();
            }
        },

        async movieFromChange(v) {
            this.movie_from = v;
            if (this.movie_from && this.movie_to) {
                await this.getCinemasList({no_pagination: true, movies_id: [this.movie_from, this.movie_to]})
            }
        },

        async movieToChange(v) {
            this.movie_to = v
            if (this.movie_from && this.movie_to) {
                await this.getCinemasList({no_pagination: true, movies_id: [this.movie_from, this.movie_to]})
            }
        },

        async getCinemasList(params = null) {
            if (this.cinemasValue.length) {
                return false
            }
            this.isCinemasLoaded = false
            this.allCinemasList = []
            let resp = await this.api.cinema.getCinemas(params);
            resp.data.forEach(item => {
                this.allCinemasList.push({
                    id: item.id,
                    label: item.name
                });
            });
            this.isCinemasLoaded = true
        },

        async getMoviesListFrom(params = null) {
            let resp = await this.api.cinema.getFilmsList({no_pagination: true});
            this.movieListFrom = []
            resp.data.forEach(item => {
                this.movieListFrom.push({
                    id: item.id,
                    label: item.name
                });
            });

            if (this.$refs.movieFrom) {
                this.$refs.movieFrom.$forceUpdate();
            }
        },
        async getMoviesListTo(params = null) {
            let resp = await this.api.cinema.getFilmsList({no_pagination: true});
            this.movieListTo = []
            resp.data.forEach(item => {
                this.movieListTo.push({
                    id: item.id,
                    label: item.name
                });
            });

            if (this.$refs.movieTo) {
                this.$refs.movieTo.$forceUpdate();
            }
        },

        async movieToChangeSearch(v) {
            if (!v) {
                v = undefined
            }
            await this.getMoviesListTo({name: v})
        },

        async movieFromChangeSearch(v) {
            if (!v) {
                v = undefined
            }
            await this.getMoviesListFrom({name: v})
        },

        multiselectChangeCinemas(v) {
            this.cinemasValue = []

            v.map(elem => {
                this.cinemasValue.push(elem.id)
            })
        },
    },

    async mounted() {
        await this.getMoviesListTo({no_pagination: true})
        await this.getMoviesListFrom({no_pagination: true})
        await this.getCities({cities: 1})
        await this.getCinemasList();
    }

}
</script>

<style lang="scss" scoped>
.dynamics {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #cad9f3;

        @media screen and (max-width: $xl) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__content {
        padding: 0 15px;
        min-height: 70vh;
    }

    &__picker {
        display: flex;
        align-items: center;

        @media screen and (max-width: $sm) {
            width: 100%;
        }
    }

    &__comparison {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 30px 0 10px;

        @media screen and (max-width: $sm) {
            flex-direction: column;
        }
    }

    &__comparisonIcon {
        font-size: 21px;
        padding: 0 4px;
        color: $color_blue;
        position: relative;
        top: 7px;
    }

    &__cinemaCity {
        width: 100%;
        display: flex;

        @media screen and (max-width: $sm) {
            flex-direction: column;
        }

        > .select-element {
            width: 100%;
        }

        > .select-element:first-child {
            width: max-content;
            margin-right: 30px;

            @media screen and (max-width: $lg) {
                width: 100%;
            }
        }
    }

    .btn-container {
        display: flex;

        @media screen and (max-width: $xl) {
            margin-top: 20px;
        }

        @media screen and (max-width: $md) {
            flex-direction: column;
        }

        @media screen and (max-width: $sm) {
            width: 100%;
        }

        .block {
            display: flex;
            align-items: center;

            &:last-child {
                margin-left: 30px;

                @media screen and (max-width: $md) {
                    margin-top: 20px;
                    margin-left: 0;
                }
            }

            &:first-child {
                @media screen and (max-width: $sm) {
                    flex-wrap: wrap;
                }

                .date-container {
                    @media screen and (max-width: $sm) {
                        margin-top: 10px;
                    }
                }
            }


        }

        .btn {
            width: 90px;
            height: 31px;
            border-radius: 2px;
            border: solid 1px #cad9f3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.35px;
            color: black;

            &:focus {
                box-shadow: none;
            }

        }

        .active {
            font-size: 14px;
            letter-spacing: 0.35px;
            text-align: center;
            color: white;
            background-color: $color_lightpink;
        }

        .sort-by {
            display: flex;

            @media screen and (max-width: $sm) {
                width: 100%;
            }

            .btn {
                width: 141px;

                @media screen and (max-width: $sm) {
                    width: 100%;
                }
            }
        }

        .label {
            font-size: 16px;
            color: #646e78;
            margin-right: 17px;
        }

        .date-container {
            display: flex;

            @media screen and (max-width: $sm) {
                width: 100%;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    ::v-deep {
        .dynamics__cinemaCity {
            > .select-element:first-child {

                .v-select {
                    width: 360px;

                    @media screen and (max-width: $lg) {
                        width: 100%;
                    }
                }
            }
        }

        .inputs-container {
            .form-group {
                display: flex;
                align-items: center;
            }

            .form-group__label {
                margin-right: 18px;
                position: relative;
                top: 4px;

                @media screen and (max-width: $sm) {
                    width: 50px;
                }
            }
        }

        .dynamics__picker {
            label {
                margin-right: 18px;
            }

            input {
                width: 300px;
                max-width: 100%;

                @media screen and (max-width: $sm) {
                    width: 100%;
                }
            }
        }
    }
}

::v-deep {
    .page-header {
        margin-top: 13px;
    }
}
</style>
